import * as React from 'react'

import { GridContainer } from '@/components/Grid'

import { Container, LeftImageWrapper, RightImageWrapper, Wrapper } from './style'
import { Image } from '@/components/FeaturedVideoBlock/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import PropTypes from 'prop-types'

const EditImages = ({ image1, alt1, image2, alt2, display }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper className={ display && 'alt' }>
          <LeftImageWrapper>
            <Image loading='lazy' src={ resolveAssetURL(image1) } alt={ alt1 } />
          </LeftImageWrapper>
          <RightImageWrapper>
            <Image loading='lazy' src={ resolveAssetURL(image2) } alt={ alt2 } />
          </RightImageWrapper>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

EditImages.propTypes = {
  image1: PropTypes.string,
  alt1: PropTypes.string,
  image2: PropTypes.string,
  alt2: PropTypes.string,
  display: PropTypes.bool
}

export default EditImages
