import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {Swiper, SwiperSlide} from 'swiper/react'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentTitle,
  Wrapper
} from './style'
import {Container} from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import {LabelIcon, LabelLink, LabelText, Overlay} from "@/components/HeroSingleJournal/style";

const HeroSingleJournal = ({
                             image,
                             title,
                             backCta,
                             date
                           }) => {

  return (
    <Wrapper>
        <Container background={resolveAssetURL(image)}>
          <Overlay>
            <Content>

              <LabelLink href={backCta}>
                <LabelIcon src='/images/long-arrow-left.svg' />
                <LabelText>Art of Entertaining</LabelText>
              </LabelLink>
              <ContentTitle>{title}</ContentTitle>
              <ContentDate>{moment(date).add(1, 'year').format('LL')}</ContentDate>
            </Content>
          </Overlay>
        </Container>
    </Wrapper>
  )
}

HeroSingleJournal.propTypes = {
  slide: PropTypes.object.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
  backCta: PropTypes.string,
  date: PropTypes.string
}

export default HeroSingleJournal
