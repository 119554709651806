import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

import { StyledGridContainer } from '@/components/Grid'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;

  ${mediaMax.xs} {
    padding: 2rem 0;
  }

  ${StyledGridContainer}{
    position: relative;

    ${mediaMax.xs} {
      padding: 0 3.6rem;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  width: 100%;
  height: 100%;

  ${mediaMax.xs} {
    flex-direction: column;
    gap: 3.6rem;
  }

  &.alt {
    flex-direction: row-reverse;

    ${mediaMax.xs} {
      flex-direction: column-reverse;
    }
  }
`

export const LeftImageWrapper = styled.figure`
  position: relative;
  width: 40rem;
  height: 39.1rem;
  overflow: hidden;
  pointer-events: none;

  ${mediaMax.xs} {
    width: 100%;
    height: 31.1rem;
  }
`

export const RightImageWrapper = styled.figure`
  position: relative;
  width: 50rem;
  height: 64.9rem;

  ${mediaMax.xs} {
    width: 100vw;
    height: 50.6rem;
  }
`
