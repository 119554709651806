import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  CloseButton,
  Container,
  ContentLeft,
  ContentRight,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  Image,
  ImageCaption,
  ImageWrapper,
  OpenButton,
  Viewer,
  ViewerImage,
  ViewerWrapper,
  Wrapper
} from './style'
import { Actions } from '@/components/Block/Text/style'

const ImageTextPortrait = ({
  title = '',
  titleTag = 'h3',
  subtitle = '',
  subtitleTag = 'span',
  text = '',
  image = '',
  alt = '',
  caption = '',
  actions = []
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openOverlay = () => {
    setIsOpen(true)
  }

  const closeOverlay = () => {
    setIsOpen(false)
  }

  return (
    <Container>
      <Wrapper>
        <Viewer isOpen={ isOpen }>
          <ViewerWrapper>
            <ViewerImage loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
          </ViewerWrapper>
          <CloseButton onClick={ closeOverlay }>
            <Image src='/images/close-button.svg' alt='close icon' />
          </CloseButton>
        </Viewer>
        <ContentLeft>
          <ImageWrapper>
            <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
            <OpenButton onClick={ openOverlay }>
              <Image src='/images/open-button.svg' alt='open icon' />
            </OpenButton>
          </ImageWrapper>
          { !!caption && (<ImageCaption>{ caption }</ImageCaption>) }
        </ContentLeft>
        <ContentRight>
          { !!subtitle && (<ContentSubtitle { ...{ as: subtitleTag } }>{ subtitle }</ContentSubtitle>) }
          { !!title && (<ContentTitle { ...{ as: titleTag } }>{ title }</ContentTitle>) }
          { !!text && (<ContentText>{ text }</ContentText>) }
          {
            !!actions.length && (
              <Actions className={ actions.length > 1 ? 'many' : 'one' }>
                { actions.map((action) => action) }
              </Actions>
            )
          }
        </ContentRight>
      </Wrapper>
    </Container>
  )
}

ImageTextPortrait.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  subtitle: PropTypes.string,
  subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  actions: PropTypes.array,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string
}

export default ImageTextPortrait
