import styled from '@emotion/styled'

import { css, keyframes } from '@emotion/react'
import { mediaMax } from '@/styles/mixins'

import { Title } from '@/components/HomeHero/Item/style.js'
import { Actions } from '@/components/Block/Text/style'
import { StyledGridContainer } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  width: 80rem;
  height: 100%;
  margin: 0 auto;

  ${mediaMax.xs} {
    gap: 4rem;
    width: 100%;
    padding: 3.2rem 0;
  }
`

export const Viewer = styled.div`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  animation: ${({ isOpen }) => isOpen ? css`${fadeIn} 0.3s ease-out forwards` : 'none'};
`

export const ViewerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4rem 10rem;

  ${mediaMax.xs} {
    padding: 0;
  }
`

export const ViewerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  ${mediaMax.xs} {
    object-fit: cover;
  }
`

const BaseButton = styled.button`
  all: unset;
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
  transition: transform 0.3s ease-out;
  pointer-events: all;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.1);
  }
`

export const CloseButton = styled(BaseButton)`
  top: 5.2rem;
  right: 11.2rem;
  z-index: 9999;

  ${mediaMax.xs} {
    top: 3.2rem;
    right: 3.2rem;
  }
`

export const OpenButton = styled(BaseButton)`
  bottom: 1.2rem;
  right: 1.2rem;
`

export const ContentTop = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMax.xs} {
    width: 100%;
  }
`

export const ImageWrapper = styled.figure`
  position: relative;
  width: 80rem;
  height: 45rem;
  overflow: hidden;

  ${mediaMax.xs} {
    width: 100%;
    height: 22rem;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ImageCaption = styled.p`
  font-size: 1.2rem;
  letter-spacing: 0.02rem;
  max-width: 80rem;
  margin-top: 1.6rem;

  ${mediaMax.xs} {
    text-align: center;
    max-width: 100%;
    padding: 0 2rem;
  }
`

export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 0 10rem;

  ${mediaMax.xs} {
    padding: 0 5.4rem 0 3.6rem;
  }

  ${Actions} {
    margin-top: 3.2rem;
  }
`

export const ContentSubtitle = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brown};
  letter-spacing: -0.03rem;
  margin-bottom: 3.2rem;

  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.subtitle.xs};
  }
`

export const ContentTitle = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brown};
  line-height: 1.2em;
  margin-bottom: 6.4rem;

  ${mediaMax.xs} {
    max-width: 100%;
    font-size: 2.4rem;
    margin-top: 2.8rem;
  }
`

export const ContentText = styled(WysiwygBase)`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.brown};
  line-height: 1.5em;
  letter-spacing: -0.03rem;

  ${mediaMax.xs} {}
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 4rem 0;
  overflow: hidden;

  ${mediaMax.md} {
    padding: 0 ${p => p.theme.paddingX.t};
  }

  ${mediaMax.xs} {
     margin: 0;
     padding: 0;

    ${StyledGridContainer} {
      padding: 0;
    }
  }
`
