import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import Layout from '@/layouts/Default'
import Events from '@/components/Events'
import ArticleBlockImage from '@/components/ArticleBlockImage'
import ArticleBlockText from '@/components/ArticleBlockText'
import ArticleBlockSimpleCaps from '@/components/ArticleBlockSimpleCaps'
import ArticleBlockHeadline from '@/components/ArticleBlockHeadline'
import ArticleBlockQuote from '@/components/ArticleBlockQuote'
import ArticleBlockCTA from '@/components/ArticleBlockCTA'
import ArticleBlockVideo from '@/components/ArticleBlockVideo'
import { marketCodeManager } from '@/recoil/marketCode'

import moment from 'moment'

import { ArticleWrapper, EventsSection } from './article.style'
import HeroSingleJournal from '@/components/HeroSingleJournal'
import ArticleTags from '@/components/ArticleTags'
import NumberContent from '@/components/NumberContent'
import EditImages from '@/components/EditImages'
import ImageTextPortrait from '@/components/ImageTextPortrait'
import Button from '@/components/Button'
import ImageLandscape from '@/components/ImageLandscape'
import TextCta from '@/components/TextCta'

const ArticlePage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const content = data.drupal.nodeById.fieldNewsBodyLayer
  const date = moment(data.drupal.nodeById?.fieldNewsDate?.date).format('LL')
  const categories = data.drupal.nodeById?.fieldNewsCategories?.map((cat) => ({ text: cat.entity?.name }))
  const location = useLocation()
  const marketCode = useRecoilValue(marketCodeManager)

  const events = data.drupal.eventsQuery.entities.map((event, index) => {
    const event_date = event?.fieldEventEndDate?.value ? event?.fieldEventEndDate?.value : event?.fieldEventStartDate?.value
    return {
      name: event?.title,
      url: event?.path?.alias,
      startDate: event?.fieldEventStartDate?.value,
      endDate: event?.fieldEventEndDate?.value,
      onGoing: moment.utc(new Date()).isBefore(event_date),
      ref: event?.fieldEventLocation,
      hours: event?.fieldEventHours,
      country: event?.fieldEventCountryLocation,
      city: event?.fieldEventLocation,
      content: event?.fieldEventDescription,
      images: [event?.fieldEventCover?.url],
      timestamp: moment(event?.fieldEventStartDate?.value).unix()
    }
  })
    .filter((event) => event.onGoing)
    .sort((ea, eb) => ea.timestamp - eb.timestamp)

  return (
    <Layout
      entityBundle='news'
      path={ pagePath }
      requireAgeGate={ requireAgeGate }
      pageType='Editorial'
      contentType={ categories }
      pageMarketCode={ pageMarketCode }
    >
      <ArticleWrapper>
        <Helmet>
          <title>{data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle}</title>
          <meta name='description' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription } />
          <meta property='og:title' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle } />
          <meta property='og:description' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription } />
          <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
          <meta property='og:site_name' content='Cloudy Bay' />
          <meta property='og:type' content='article' />
          {data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage && <meta property='og:image' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage?.url } /> }
        </Helmet>

        <HeroSingleJournal
          title={ data.drupal.nodeById?.fieldNewsTitle }
          image={ isMobile ? data.drupal.nodeById?.fieldNewsCoverMobile?.url : data.drupal.nodeById?.fieldNewsCover?.url }
          backCta={ `/${marketCode}/journal` }
        />

        {content.map((row) => {
          console.log('row.entity.type.targetId')
          console.log(row.entity.type.targetId)
          switch (row.entity.type.targetId) {
            case 'numbered_list':
              return (
                <NumberContent
                  items={ row.entity.fieldItems.map((item) => ({ number: item.entity.fieldNumber, text: item.entity.fieldText })) }
                />
              )

            case 'news_images':
              return (
                <EditImages
                  display={ row.entity.fieldDisplay }
                  image1={ row.entity.fieldImageLeft?.url }
                  alt1={ row.entity.fieldImageLeft?.alt }
                  image2={ row.entity.fieldImageRight?.url }
                  alt2={ row.entity.fieldImageRight?.alt }
                />
              )

            case 'news_text_image_portrait':
              return (
                <ImageTextPortrait
                  subtitle=''
                  title=''
                  text={ row.entity.fieldNewsPortraitText }
                  image={ row.entity.fieldNewsPortraitImage?.url }
                  alt={ row.entity.fieldNewsPortraitImage?.alt }
                  caption={ row.entity.fieldNewsPortraitImage?.title }
                  actions={ [
                    <Button key={ 1 } to={ row.entity.fieldNewsPortraitLink.url.path }>{ row.entity.fieldNewsPortraitLink.title }</Button>
                  ] }
                />
              )

            case 'news_text_cta':
              return (
                <TextCta
                  text={ row.entity.fieldNewsTextCtaContent }
                  actions={ row.entity.fieldNewsTextCtaLink.map((link, i) => <Button key={ i } target='_blank' to={ link.url.path }>{link.title}</Button>) }
                />
              )
            case 'news_image_landscape':
              return (
                <ImageLandscape
                  image={ row.entity.fieldNewsLandscapeImage?.url }
                  alt={ row.entity.fieldNewsLandscapeImage?.alt }
                  caption={ row.entity.fieldNewsLandscapeImage?.title }
                  isLarge={ row.entity.fieldNewsLandscapeDisplay }
                />
              )

            case 'simple_caps_section':
              return (
                <ArticleBlockSimpleCaps
                  text={ row.entity.fieldSimpleCapsSectContent }
                />
              )

            case 'headline':
              return (
                <ArticleBlockHeadline
                  text={ row.entity.fieldHeadlineContent }
                />
              )

            case 'simple_text_1_column':

              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt1colSectContent }
                />
              )

            case 'simple_text_2_columns':
              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt2colTxtColumn1 }
                  text2={ row.entity.fieldSimpTxt2colTxtColumn2 }
                />
              )

            case 'simple_image':
              return (
                <ArticleBlockImage
                  src={ isMobile ? row.entity.fieldSimpleImageMobile?.url : row.entity.fieldSimpleImageDesktop?.url }
                />
              )

            case 'standard_quote_with_author':
              return (
                <ArticleBlockQuote
                  author={ row.entity.fieldStandardQuoteAuthorName }
                  quote={ row.entity.fieldStandardQuoteAuthorText }
                />
              )

            case 'cta_block':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldCtaBlockCta?.url?.path }
                  label={ row.entity.fieldCtaBlockCta?.title }
                />
              )

            case 'file_linik':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldStandardFile?.entity?.url }
                  label={ row.entity.fieldStandardFile?.description }
                  target='_blank'
                />
              )

            case 'standard_single_video':
              return (
                <ArticleBlockVideo
                  url={ row.entity.fieldStandardSingleVimeoLink?.uri }
                />
              )
          }
        })}

        <ArticleTags
          tags={ categories }
        />

        {events.length > 0 && (
          <EventsSection>
            <Events
              title='Upcoming Events'
              subtitle='events'
              items={ events }
            />
          </EventsSection>
        )}
      </ArticleWrapper>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
query ArticleQuery($nid: String!){
  drupal {
    nodeById(id: $nid) {
      title
      ... on Drupal_NodeNews {
        nid
        fieldNewsTitle
        fieldNewsShortDescription
        fieldNewsIntroduction
        fieldNewsMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                url
              }
            }
          }
        }
        fieldNewsDate {
          date
        }
        fieldNewsCoverMobile {
          alt
          url
          title
        }
        fieldNewsCover {
          alt
          title
          url
        }
        fieldNewsCategories {
          entity {
            ... on Drupal_TaxonomyTermNewsCategories {
              entityRendered
              tid
              name
            }
          }
        }
        fieldNewsBodyLayer {
          entity {
            id
            parentFieldName
            type {
              targetId
            }
            ... on Drupal_ParagraphSimpleText1Column {
              id
              fieldSimpTxt1colSectContent
            }
            ... on Drupal_ParagraphNumberedList {
              fieldItems {
                entity {
                  ... on Drupal_ParagraphNumberedItems {
                    fieldNumber
                    fieldText
                  }
                }
              }
            }
            ... on Drupal_ParagraphNewsImages {
              fieldDisplay
              fieldImageRight {
                alt
                url
              }
              fieldImageLeft {
                alt
                url
              }
            }
            ... on Drupal_ParagraphNewsTextImagePortrait {
              fieldNewsPortraitImage {
                alt
                url
                title
              }
              fieldNewsPortraitText
              fieldNewsPortraitLink {
                url {
                  path
                }
                title
              }
            }
            ... on Drupal_ParagraphNewsTextCta {
              id
              fieldNewsTextCtaContent
              fieldNewsTextCtaLink {
                url {
                  path
                }
                title
              }
            }
            ... on Drupal_ParagraphNewsImageLandscape {
              fieldNewsLandscapeDisplay
              fieldNewsLandscapeImage {
                alt
                url
                title
              }
            }

            ... on Drupal_ParagraphHeadline {
              id
              fieldHeadlineContent
            }
            ... on Drupal_ParagraphSimpleCapsSection {
              id
              fieldSimpleCapsSectContent
            }
            ... on Drupal_ParagraphSimpleText2Columns {
              id
              fieldSimpTxt2colTxtColumn1
              fieldSimpTxt2colTxtColumn2
            }
            ... on Drupal_ParagraphSimpleImage {
              id
              fieldSimpleImageDesktop {
                alt
                url
              }
              fieldSimpleImageMobile {
                alt
                url
              }
            }
            ... on Drupal_ParagraphStandardQuoteWithAuthor {
              id
              fieldStandardQuoteAuthorName
              fieldStandardQuoteAuthorText
            }
            ... on Drupal_ParagraphCtaBlock {
              id
              fieldCtaBlockCta {
                url {
                  path
                }
                title
              }
            }
            ...on Drupal_ParagraphStandardSingleVideo {
              fieldStandardSingleVimeoLink {
                uri
              }
            }
          }
        }
      }
    }
    eventsQuery: nodeQuery(
      filter: {conditions: [
          {field: "type", value: "events", operator: EQUAL},
          # {field: "field_event_country", value: [$marketId], operator: EQUAL},
          {field: "field_event_types", value: "journal", operator: EQUAL}
          {operator: EQUAL, field: "status", value: "1"}
        ]}
        limit: 1000
      ) {
      entities {
        ... on Drupal_NodeEvents {
          title
          path{
            alias
          }
          fieldEventStartDate {
            value
            date
          }
          fieldEventEndDate {
            value
            date
          }
          fieldEventCountry {
            targetId
          }
          fieldEventCountryLocation
          fieldEventLocation
          fieldEventCover {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventCoverMobile {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventDescription
          fieldEventHours
        }
      }
    }
  }
}
`
