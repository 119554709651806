import * as React from 'react'

import { Container, TagButton, TagText, Wrapper } from './style'
import PropTypes from 'prop-types'
import { GridContainer } from '@/components/Grid'

const ArticleTags = ({ tags }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          {tags.map((tag, index) => (
            <TagButton key={ `tag-${index}` }>
              <TagText>{tag.text}</TagText>
            </TagButton>
          ))}
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

ArticleTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string
  }))
}

export default ArticleTags
